@font-face {
  font-family: 'Frutiger LT Std';
  src: url('./assets/fonts/FrutigerLTStd-Black.woff') format('woff2'),
      url('assets/fonts/FrutigerLTStd-BlackCn.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-Roman.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-Light.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-LightCn.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-LightCn.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-BlackItalic.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-Black.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-BoldItalic.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-Cn.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-Cn.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-LightItalic.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-Italic.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-Bold.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-ExtraBlackCn.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-ExtraBlackCn.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('assets/fonts/FrutigerLTStd-BoldCn.woff2') format('woff2'),
      url('assets/fonts/FrutigerLTStd-BoldCn.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Frutiger LT Std';
  src: url('./assets/fonts/FrutigerLTStd-UltraBlack.woff2') format('woff2'),
      url('./assets/fonts/FrutigerLTStd-UltraBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Frutiger LT Std', sans-serif;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.8s ease-out;
}

a:hover {
  color: #ef8026;
}

button {
  background-color: #fff;
  cursor: pointer;
  border: none;
}

p {
  font-size: 1.1rem;
  text-align: justify;
}

ul li {
  font-size: 1.5rem;
}

.card:hover,
.card_home:hover {
  transition: all 0.8s ease-out;
  background-color: #1c2544;
}

.card:hover h3,
.card_home:hover h3 {
  color: #fff;
  transition: all 0.8s ease-out;
}


.button_history:hover {
  -webkit-box-shadow: 10px 10px 14px 2px rgba(0,0,0,0.47);
  box-shadow: 10px 10px 14px 2px rgba(0,0,0,0.47);
}

.button_history a:hover {
  color: #1c2544;
}

.contact-facebook:hover img,
.contact-instagram:hover img,
.contact_button:hover,
.logo-header:hover,
.schedule-appointment img:hover {
  opacity: 0.3;
}

.return-container p:hover {
  color: #ef8026;
  transition: all 0.8s ease-out;
  font-size: medium;
}

hr {
  width: 0.1vw;
  height: 3vh;
  background-color: #fff;
  border-color: transparent;
}

/* Header */
header {
  display: flex;  
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 6em;
  z-index: 10;
  background-color: #fff;
  padding: 0 2rem;
}

.logo {
  /* padding:  5%; */
  padding: 1rem 0;
}

.icon-contact a img,
.logo img {
  width: 50%;
}

.list-links{
  display: none;
}

.list-links-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 45px;
  height: 100vh;
}

.hamburger {
  padding: 2% 2% 1% 2%;
  border: #1c2544 1px solid;
  border-radius: 8px;
  height: fit-content;
}

header button {
  background-color: transparent;
}

/* ok Home */
.container_top_home {
  position: relative;
  background-image: url(./assets/home/frontImage.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  height: 45vh;
}

.container_cards_home {
  width: 100%;
  bottom: 0%;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  column-gap: 10%;
  row-gap: 1em;
  padding: 2rem 2rem;
}

.container_top_home_black {
  display: flex;
  flex-direction: column;
  width: fit-content;
  background-color: rgb(28,37,68, 0.6);
  color: #fff;
  font-size: x-small;
  padding: 5%;
  padding-left: 10%;
  font-size: large;
}

.container_top_home_black p span {
  font-weight: bold;
}

.container_top_home_black button {
  background-color: #ef8026;
  padding: 4%;
  font-size: xx-small;
  margin-top: 10%;
  width: 60%;
}

.container_contact_home {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5%;
  padding: 5%;
  margin-top: -35%;
}

.container_home_bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
}

.container_home_bottom div img {
  width: 100%;
}

.container_home_title_paragraph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
  color: #fff;
  background-color: #1c2544;
  font-size: smaller;
}

.orange-line {
  width: 100%;
  height: 5px;
  background-color: #ef8026;
}

/* ok Whatsapp Button */
.contact_button {
  display: flex;
  width: 150px;
  padding-left: 5%;
  padding-bottom: 5%;
}

/* Installations */
.installations-container {
  display: flex;
  flex-direction: column;
  padding: 8em 5% 5% 5%;
  color: #7c8a9e;
}

.container-images-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.container-images-top img {
  padding-top: 0;
}

.arrow-images {
  height: 30px;
}

.information-paragraph {
  padding-bottom: 20px;
}


/* Return Page */
.return-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2%;
}

.return-container button {
  display: flex;
  align-items: center;
  border: none;
  font-size: x-small;
  color: #7c8a9e;
  background-color: transparent;
}

.return-container button img {
  width: 20px;
  padding-right: 5%;
}

.front-page-history-container div {
  padding: 1%;
  height: 5%;
}

h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
  font-size: 2rem;
  font-weight: bold;
  color: #1c2544;
  text-align: center;
  padding-top: 5%;
}

/* Container Information */
.container-title {
  display: flex;
  padding-bottom: 3%;
  align-items: center;
}
.service-container-title {
  display: flex;
  padding-bottom: 3%;
  align-items: center;
}

.subTitle-admission,
.subTitle-information {
  font-size: medium;
  font-weight: bold;
  color: #ef8026;
  padding-bottom: 2%;
  padding-top: 1%;
}

.paragraph2 {
  padding: 5% 0 5% 0;
}

/* Title */
.icon-title {
  display: flex;
  width: 40px;
  height: 40px;
  padding-right: 2%;
}

.hide {
  display: none;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  color: #1c2544;
  text-decoration: underline;
  text-decoration-color: #ef8026;
}

.title-service {
  font-size: 2.3rem;
  font-weight: bold;
  color: #1c2544;
  text-decoration: underline;
  text-decoration-color: #ef8026;
  text-align: left;
  padding-top: 6px;
}

.icon-title-service {
  display: flex;
  width: 30px;
  height: 30px;
  padding-right: 2%;
}

.title-small {
  font-size: 2rem;
  font-weight: bold;
  color: #1c2544;
  text-decoration: underline;
  text-decoration-color: #ef8026;
}

.title-orange {
  color: #ef8026;
}

/* Container Images */
.images-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.photos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  padding-top: 5%;
}

/* Services */
.services_page {
  margin-top: 100px;  
  padding: 5%;
}
.services-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  align-items: center;
}

.contact-container-history,
.contact-container,
.home-container {
  padding-top: 6em;
  flex-direction: column;
  align-items: center;
}

.contact-container {
  flex-direction: row;
  align-items: flex-start;
}
.contact-container p {
  font-size: x-large;
}

/* Cards */
.card_home,
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-shadow: 7px 7px 15px #1c2544; 
  background-color: #fff;
  width: 140px;
  height: 140px;
  padding: 5%;
  margin-bottom: 5%;
  cursor: pointer;
}

.card_home {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 5/4;
  @supports not (aspect-ratio: 5 / 4) {
    &::before {
      float: left;
      padding-top: calc((5/4) * 100%);
      content: "";
    }

    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
  margin-bottom: 0;
}

.card img {
  width: 55px;
  padding-bottom: 5%;
}

.card_home img {
  position: absolute;
  width: 27px;
  top: 5%;
  left: 5%;
}

.card_home h3,
.card h3 {
  color: #1c2544;
  font-size: medium;
  text-align: center;
}

.card_home h3 {
  font-size: 1.3rem;
}

/* Specialties */
.list-information {
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  padding-left: 5%;
  padding-bottom: 5%;
}

.list-information li::marker {
  color: #ef8026;
}

.list-information li {
  padding-bottom: 2%;
}

/* Care and Protection*/
.schedule-appointment {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

.schedule-appointment img {
  width: 100%;
}

/* Contact*/
.contact-container {
  display: flex;
  /* align-items: center; */
}

.contact-content-left {
  padding: 5% 0 5% 5%;
  width: 100%;
}

.contact-content-right {
  display: none;
}

.contact-list-icons {
  width: 5%;
  height: 5%;
  padding-right: 2%;
}

.contact-list-icons_fotoer {
  width: 20px;
  margin-right: 1rem;
  padding-bottom: 0.5rem;
}

.contact-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 5% 5%;
}

.contact-list li p{
  font-size: 1.4rem;
  color: #1c2544;
}

.contact-list li .content,
.contact-list li div .content
{
  padding-bottom: 0.5rem;
  color: white;
}

.contact-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  padding: 0 0 5% 5%;
}

.facebook-instagram-container {
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 15px;
  padding-left: 10%;
}

.contact-facebook img,
.contact-instagram img {
  width: auto;
  height: 30px;
}


.title-level3 {
  color: #1c2544;
  font-size: x-large;
}

/* Admission page */
.company-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo-icon {
  width: 50%;
  padding: 5%;
}

/* Our History page */
.history-mission-vision-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 13%;
  padding: 5%;
}

.mission-vision-container {
  display: flex;
  flex-direction: column;
}

.mission-vision {
  display: flex;
  padding: 2%;
}

.mission-vision img {
  padding-right: 5%;
  width: 50px;
}

.paragraph-container {
  padding: 0 5% 5% 5%;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1c2544;
  padding: 5%;
  color : #fff;
}

.title-level3-white {
  margin-top: 2rem;
  font-size: 1.2rem;
  color : #fff;
}

/* Footer */
.footer_contact {
  display: none;
}

.footer_logo_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-footer {
  width: 50%;
}

.history-mission-vision-container img {
  width: 50%;
}

.return-page-history {
  display: none;
}

.menu-container {
  background-color: #1c2544;
}

/* Slider */
.slider_container {
  display: flex;
  width: 100%;
  gap: 1rem;
}
.slider_container__items_container {
  width: 100%;
  overflow: hidden;
}
.slider_container__items_container div {
  display: flex;
  /* flex-direction: column; */
  transition: all 1s ease 0s;
}

@media only screen and (min-width: 980px) {
  /* Header */
  .menu-container,
  .hamburger {
    display: none;
  }

  header {
    background-color: #1c2544;
    display: flex;
    justify-content: space-between;
    height: 6em;
    padding: 0;
  }

  nav {
    display: flex;
    height: 100%;
    padding-right: 70px;
  }

  .logo {
    display: flex;
    justify-content: end;
    align-items: center;
    background-color: #fff;
    width: fit-content;
    padding-left: 5%;
    padding-right: 2%;
    box-sizing: border-box;
    height: 100%;
  }

  .logo img {
    width: 80px;
  }

  .list-links {
    display: flex;
    align-items: center;
  }

  .list-links li {
    padding-right: 20px;
    font-size: x-large;
  }

  .facebook img,
  .instagram img {
    height: 20px;
  }

  .facebook {
    padding-right: 1%;
  }

  .instagram {
    border-left: 1px solid #fff;
    padding-left: 1%;
  }
  /* Home */
  .home-container {
    padding-top: 6em;
  }
  /* ok Home*/
  .contact_button {
    width: 250px;
  }

  .card_home {
    width: 180px;
    height: 180px;
  }

  .card_home h3 {
    font-size: x-small;
  }

  .title {
    font-size: 3.5rem;
  }

  /* Care and Protection*/
  .schedule-appointment {
    display: flex;
  }

  .schedule-appointment button {
    width: 60%;
    border: none;
  }

  .schedule-appointment button img {
    width: 100%;
  }

  /* Contact*/
  .contact-facebook img,
  .contact-instagram img {
    width: auto;
    height: 18px;
  }

  h1 {
    font-size: 3rem;
  }

  .history-mission-vision-container img {
    width: 40%;
  }

  .mission-vision img {
    width: 70px;
  }

  .contact-content-left {
    width: 50%;
    font-size: x-small;
  }
}

.container_home_box {
  display: flex;
  flex-direction: column !important;
}

.container_home_content {
  display: flex;
  flex-direction: column !important;
}

@media only screen and (min-width: 768px) {
  .front-page-history-container {
    background-image: url(./assets/history/historyPhoto.png);
    background-size: cover;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Header */
  .list-links li {
    /* padding-right: 2%; */
    font-size: x-small;
  }
  
  .facebook img,
  .instagram img {
    height: 25px;
  }

  .title-service {
    font-size: 1.6rem;
  }
  .icon-title-service {
    width: 25px;
    height: 25px;
  }

  /* Home */
  .container_cards_home {
    column-gap: 7%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    /* column-gap: 10%; */
    row-gap: 1em;
  }

  .card_home {
    width: 150px;
    height: 150px;
  }

  .card_home img {
    width: 18%;
  }

  .container_contact_home {
    margin-top: -5%;
  }

  .contact_button {
    width: 250px;
  }

  .card_home h3 {
    font-size: 1.3rem;
  }

  .container_top_home {
    position: relative;
    background-size: cover;
    height: 60vh;
  }

  .container_top_home_black {
    position: absolute;
    top: 15%;
    min-width: 250px;
    width: fit-content;
    font-size: medium;
    padding: 4% 2% 2% 5%;
  }

  .container_top_home_black button {
    font-size: small;
  }

  .container_top_home_black p {
    font-size: xx-large;
  }
  .container_top_home_black p span{
    font-weight: bold;
  }

  .container_home_bottom div {
    display:flex;
    flex-direction: row;
  }

  .container_home_bottom div img {
    width: 50%;
  }

  .container_home_title_paragraph {
    display: flex;
    flex-direction: column !important;
  }

  .container_home_box {
    display: flex;
    flex-direction: column !important;
  }

  .container_home_content {
    flex-direction: row !important;
  }
  

  .slider_container__items_container div {
    display: flex;
  }

  /* Title */
  .icon-title {
    width: 50px;
    height: 50px;
  }

  /* Installations */
  .title-orange {
    font-size: 1.8rem;
  }

  /* Installations */
  .installations-content {
    display: flex;
    flex-direction: row;
  }

  /* Container information */
  .container-information {
    width: 45%;
    padding: 0% 5% 5% 0%;
  }

  /* Container Images */
  .images-container {
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 5%;
    width: 50%;
  }

  /* Return Page */
  .return-container {
    padding-right: 5%;
  }

  /* Services */
  .services-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2%;
    padding-top: 0;
  }

  /* Cards */
  .card {
    margin: 3%;
  }

  .card img {
    width: 60px;
  }

  /* Contact*/
  .contact-content-right {
    display: flex;
    width: 55%;
  }

  .contact-logo {
    width: 250px;
    height: auto;
    padding: 0 0 5% 5%;
  }

  /* Admission Page*/
  .company-logos, 
  .subTitle-admission {
    padding-left: 5%;
  }

  .company-logos {
    width: 90%;
    padding-bottom: 2%;
  }

  /* Plans Page*/
  .plans-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 2%;
  }

  /* Footer */
  .footer_contact {
    display: flex;
  }

  .footer_contact li {
    padding: 0;
    font-size: small;
  }

  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 3% 5% 3% 5%;
  }

  .footer_logo_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-facebook img,
  .contact-instagram img {
    width: auto;
    height: 30px;
  }

  .history-mission-vision-container {
    column-gap: 5%;
  }

  .mission-vision-container img {
    height: 70px;
    width: 70px;
  }

  .history-mission-vision-container {
    flex-direction: row;
  }

  .mission-vision img {
    width: 80px;
  }

  .information-paragraph {
    font-size: 1.7rem;
    line-height: 2rem;
    font-weight: 300;
  }

  .subTitle-information {
    font-size: 1.5rem;
  }

  .return-page-history {
    display: block;
  }

  h1 {
    padding-top: 0;
  }
}

@media only screen and (min-width: 1024px) {
  /* Header */
  .list-links li {
    font-size: 1.25rem;
  }

  /* Title  */
  .title-service {
    font-size: 2.15rem;
  }
  .icon-title-service {
    width: 35px;
    height: 35px;
  }

  /* ok Home */
  .container_top_home {
    position: relative;
    /* background-size: 100%; */
  }

  .container_home_bottom {
    padding: 0 10% 10% 10%;
  }
  .logo img {
    width: 150px;
  }
  .container_cards_home {
    /* padding: 27% 10% 0 10%; */
  }

  /* Whatsapp Bottom */
  .contact_button {
    width: 350px;
  }

  /* Return Page */
  .return-container button {
    font-size: small;
  }

  .return-container button img {
    width: 30px;
  }

  /* Cards */
  .container_cards_home {
    column-gap: 6.6rem;
  }

  .card_home {
    width: 175px;
    height: 175px;
  }

  .card img {
    width: 60px;
    padding-bottom: 10%;
  }

  /* List information */
  .list-information {
    font-size: 5rem;
    display: block;
    column-count: 2;
    padding: 5%;
  }

  .list-information li {
    padding-bottom: 5%;
  }

  /* Footer */
  footer {
    padding: 3% 10% 3% 10%;
  }

  .card_home h3 {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 4rem;
    padding-top: 0;
  }

  .history-mission-vision-container img {
    width: 25%;
  }

  .mission-vision img {
    width: 100px;
  }

  /* Plans */
  .contact-logo {
    width: 200px;
  }
}
@media only screen and (min-width: 1260px) {
  .title-service {
    font-size: 2.4rem;
  }
}
@media only screen and (min-width: 1440px) {
  /* Title */
  .icon-title {
    width: 60px;
    height: 60px;
  }

  .title-service {
    font-size: 2.6rem;
  }
  .icon-title-service {
    width: 45px;
    height: 45px;
  }
  /* Home */
  .container_cards_home {
    column-gap: 7%;
  }

  .card_home {
    width: 220px;
    height: 220px;
  }
  .card_home h3{
    font-size: 1.75rem;
  }
}